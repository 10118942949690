import { EnumClass } from "./EnumClass";
// import { PRICE_QUOTATION_HISTORY_WORK_TYPES } from "./../../utils/constantLists";

export const ModulesPrivilege = {
  // custom
  Notification: "Notification_",

  //AdditionalSetting,
  Category: "Category_",
  Product: "Product_",
  UserWalletTransaction: "UTT_12800UserWalletTransaction_",
  EducationalStage: "EducationalStage_",
  UserEducationalStage: "UserEducationalStage_",
  UserOrder: "UTT_12800UserOrder_",

  //*******************Student******************,
  Student: EnumClass.UserType.Student,
  StudentEstablishmentsRole:
    EnumClass.UserType.Student + EnumClass.UsersModules.EstablishmentsRoles,
  StudentLoginCode:
    EnumClass.UserType.Student + EnumClass.UsersModules.UserLoginCode,
  StudentValidSetting:
    EnumClass.UserType.Student + EnumClass.UsersModules.UserValidSetting,

  //*******************Parent******************,
  Parent: EnumClass.UserType.Parent,
  ParentValidSetting:
    EnumClass.UserType.Parent + EnumClass.UsersModules.UserValidSetting,
  ParentEstablishmentsRole:
    EnumClass.UserType.Parent + EnumClass.UsersModules.EstablishmentsRoles,
  ParentLoginCode:
    EnumClass.UserType.Parent + EnumClass.UsersModules.UserLoginCode,

  //*******************Employee******************,
  Employee: EnumClass.UserType.Employee,
  EmployeeValidSetting:
    EnumClass.UserType.Employee + EnumClass.UsersModules.UserValidSetting,
  EmployeeEstablishmentsRole:
    EnumClass.UserType.Employee + EnumClass.UsersModules.EstablishmentsRoles,
  EmployeeLoginCode:
    EnumClass.UserType.Employee + EnumClass.UsersModules.UserLoginCode,

  //*******************MasterAdmin******************,
  MasterAdmin: EnumClass.UserType.MasterAdmin,
  MasterAdminValidSetting:
    EnumClass.UserType.MasterAdmin + EnumClass.UsersModules.UserValidSetting,
  MasterAdminEstablishmentsRole:
    EnumClass.UserType.MasterAdmin + EnumClass.UsersModules.EstablishmentsRoles,
  MasterAdminLoginCode:
    EnumClass.UserType.MasterAdmin + EnumClass.UsersModules.UserLoginCode,

  //*******************AdminsEgypt******************,
  AdminsEgypt: EnumClass.UserType.AdminsEgypt,
  AdminsEgyptValidSetting:
    EnumClass.UserType.AdminsEgypt + EnumClass.UsersModules.UserValidSetting,
  AdminsEgyptEstablishmentsRole:
    EnumClass.UserType.AdminsEgypt + EnumClass.UsersModules.EstablishmentsRoles,
  AdminsEgyptLoginCode:
    EnumClass.UserType.AdminsEgypt + EnumClass.UsersModules.UserLoginCode,
};

export const GeneralFun = {
  // view
  viewActive: EnumClass.ActivationType.Active + "1",
  viewArchived: EnumClass.ActivationType.Archived + "1",
  viewBlocked: EnumClass.ActivationType.Blocked + "1",
  viewDialogActive: EnumClass.ActivationType.Active + "4",
  viewDialogArchived: EnumClass.ActivationType.Archived + "4",
  viewDialogBlocked: EnumClass.ActivationType.Blocked + "4",
  viewReportActive: EnumClass.ActivationType.Active + "7",
  viewReportArchived: EnumClass.ActivationType.Archived + "7",
  viewReportBlocked: EnumClass.ActivationType.Blocked + "7",
  // actions
  itemAdd: "1",
  itemUpdate: "3",
  itemActive: "5",
  itemArchived: "7",
  itemBlocked: "9",
  itemDeleteImage: "11",
  itemFinaleDelete: "13",
};

// general functions for login
export const LoginGeneralFunctions = {
  ...GeneralFun,
  // custom
  view: GeneralFun.viewActive,
  viewReport: "AST-174007",

  //  Notification
  notificationView: ModulesPrivilege.Notification + "1",
  notificationRead: ModulesPrivilege.Notification + "2",
  notificationOpen: ModulesPrivilege.Notification + "3",
  notificationArchive: ModulesPrivilege.Notification + "4",
  notificationAdd: ModulesPrivilege.Notification + "5",
  //  UserWalletTransaction
  userWalletTransactionView: "AST-174001",
  userWalletTransactionCharging: "UserWalletTransactions_333",
  userWalletTransactionWithdrawing: "UserWalletTransactions_334",
};
// general functions for view
export const GeneralFunctions = {
  // view
  viewSelfDataActive: EnumClass.ActivationType.Active + "2",
  viewAllDataActive: EnumClass.ActivationType.Active + "3",
  viewSelfDataArchived: EnumClass.ActivationType.Archived + "2",
  viewAllDataArchived: EnumClass.ActivationType.Archived + "3",
  viewSelfDataBlocked: EnumClass.ActivationType.Blocked + "2",
  viewAllDataBlocked: EnumClass.ActivationType.Blocked + "3",
  // dialog
  viewDialogSelfDataActive: EnumClass.ActivationType.Active + "5",
  viewDialogAllDataActive: EnumClass.ActivationType.Active + "6",
  viewDialogSelfDataArchived: EnumClass.ActivationType.Archived + "5",
  viewDialogAllDataArchived: EnumClass.ActivationType.Archived + "6",
  viewDialogSelfDataBlocked: EnumClass.ActivationType.Blocked + "5",
  viewDialogAllDataBlocked: EnumClass.ActivationType.Blocked + "6",
  // report
  reportingSelfDataActive: EnumClass.ActivationType.Active + "8",
  reportingAllDataActive: EnumClass.ActivationType.Active + "9",
  reportingSelfDataArchived: EnumClass.ActivationType.Archived + "8",
  reportingAllDataArchived: EnumClass.ActivationType.Archived + "9",
  reportingSelfDataBlocked: EnumClass.ActivationType.Blocked + "8",
  reportingAllDataBlocked: EnumClass.ActivationType.Blocked + "9",
  notificationOnReportingData: "15",
  // actions
  itemAdd: GeneralFun.itemAdd,
  notificationOnItemAdd: "2",
  itemUpdate: GeneralFun.itemUpdate,
  notificationOnItemUpdate: "4",
  itemActive: GeneralFun.itemActive,
  notificationOnItemActive: "6",
  itemArchive: GeneralFun.itemArchived,
  notificationOnItemArchive: "8",
  itemBlocked: GeneralFun.itemBlocked,
  notificationOnItemBlocked: "10",
  itemDeleteImage: GeneralFun.itemDeleteImage,
  itemFinaleDelete: GeneralFun.itemFinaleDelete,
  notificationOnDeleteImage: "12",
  notificationOnFinaleDeleteItem: "14",
};
